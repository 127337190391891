import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import NProgress from 'nprogress';
import { debounce } from 'underscore';

import TeamActions from 'actions/team-actions';
import Select from 'components/shared/select';
import CopyableLink from 'components/shared/copyable-link';
import Flash from 'components/shared/flash';
import TeamAPI from 'apis/team-api';
import { publicHost } from 'modules/url-generator';
import { slugIsValid } from 'modules/slug-validator';
import NotifBanner from 'components/shared/notif-banner';
import AttachedPeople from 'components/shared/attached-people';
import DomainManager from './teams/domain-manager';
import PublicPortalHeader from './team_page/public-portal-header';

const defaultTeamColor = '#363636';

class TeamPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: this.props.team,
      newTimeZone: this.props.team.time_zone,
      newTeamColor: this.props.team.color,
      newTeamName: this.props.team.name,
      newSlug: this.props.team.friendly_id,
      newLogoPresent: false,
      savingTeamName: false,
      savingTeamColor: false,
      newSlugAvailable: null,
      slugSaving: false,
      slugChecking: false,
    };
  }

  componentDidMount() {
    this.doCheckSlug = debounce(this.doCheckSlug, 350);
  }

  renderDefaultStatus = () => {
    if (this.state.team.default && this.state.team.creator_id === this.props.currentUserId) {
      return <div className='margin-bottom'>This is your default team</div>;
    }
  };

  renderTeamLogo = () => {
    if (this.state.team.logo.present) {
      return <img className='imgborder margin-top-less' src={this.state.team.logo.url} alt='Team logo' height='50px' />;
    }
  };

  nameDidChange = () => {
    return this.state.newTeamName !== this.state.team.name;
  };

  onTeamNameSave = () => {
    this.setState({ savingTeamName: true });

    TeamActions.update(this.state.team.id, { name: this.state.newTeamName })
      .done(this.teamNameDidSave)
      .always(() => { this.setState({ savingTeamName: false }); });
  };

  teamNameDidSave = () => {
    const { team } = this.state;
    team.name = this.state.newTeamName;
    this.setState({ team });

    Flash.success('Team name updated');
  };

  onTeamNameChanged = (e) => {
    this.setState({ newTeamName: e.currentTarget.value });
  };

  shouldAllowTeamNameSave = () => {
    return this.nameDidChange() && !this.state.savingTeamName;
  };

  shouldAllowColorSave = () => {
    if (this.props.disabled) { return false; }

    return this.colorWasModified() && !this.state.savingTeamColor;
  };

  shouldAllowSlugSave = () => {
    return slugIsValid(this.state.newSlug)
            && this.slugWasModified()
            && !!this.state.newSlugAvailable
            && !this.state.slugSaving;
  };

  slugWasModified = () => {
    return this.state.newSlug !== this.state.team.friendly_id;
  };

  colorWasModified = () => {
    // if color has already been assigned to team
    if (this.state.team.color) {
      return this.state.newTeamColor !== this.state.team.color;
    }

    // if using defaultTeamColor and color was modified in state
    if (this.state.newTeamColor) {
      return this.state.newTeamColor !== defaultTeamColor;
    }
  };

  onSlugChanged = (e) => {
    const newValue = e.currentTarget.value;

    this.setState({
      newSlugAvailable: null,
      newSlug: newValue,
    }, this.checkSlugAvailable);
  };

  checkSlugAvailable = () => {
    if (!this.slugWasModified() || !slugIsValid(this.state.newSlug)) { return; }
    this.setState({ slugChecking: true });
    this.doCheckSlug();
  };

  doCheckSlug = () => {
    TeamAPI.checkSlugAvailable(this.state.team.friendly_id, this.state.newSlug)
           .done((res) => { this.setState({ newSlugAvailable: res.available }); })
           .fail(() => { Flash.error(); })
           .always(() => { this.setState({ slugChecking: false }); });
  };

  textForSlugSaveButton = () => {
    if (!slugIsValid(this.state.newSlug)) {
      return 'URL not valid';
    }
    if (this.state.slugChecking) {
      return 'Checking availability';
    }
    if (this.state.newSlugAvailable === false) {
      return 'URL not available';
    }
    return 'Save';
  };

  onColorChange = ({ hex }) => {
    this.setState({ newTeamColor: hex });
  };

  onColorSave = () => {
    this.setState({ savingTeamColor: true });

    TeamActions.update(this.props.team.id, { color: this.state.newTeamColor })
      .done(() => {
        this.setState(({ team, newTeamColor }) => ({ team: { ...team, color: newTeamColor } }));
        Flash.success('Changes saved');
      })
      .always(() => { this.setState({ savingTeamColor: false }); });
  };

  onSlugSave = () => {
    if (!this.shouldAllowSlugSave()) { return; }

    this.setState({ slugSaving: true });
    TeamActions.onSlugSave(this.state.team.id, this.state.newSlug)
      .done(() => {
        this.setState(({ team, newSlug }) => ({
          team: { ...team, slug: newSlug, friendly_id: newSlug },
        }));
      })
      .always(() => {
        this.setState({ slugSaving: false });
      });
  };

  publicPortalURL = () => {
    return `${publicHost()}/${this.state.newSlug}`;
  };

  onTimeZoneChange = ({ value }) => {
    this.setState({
      newTimeZone: value,
    });
  };

  onTimeZoneSave = () => {
    TeamActions.update(this.state.team.id, { time_zone: this.state.newTimeZone })
      .done(this.timeZoneDidSave);
  };

  timeZoneDidSave = () => {
    this.setState(({ team, newTimeZone }) => ({ team: { ...team, time_zone: newTimeZone } }));
    Flash.success('Time zone updated');
  };

  shouldAllowTimeZoneSave = () => {
    return (this.state.newTimeZone !== this.state.team.time_zone);
  };

  onLogoChange = (e) => {
    const logoPresent = e.currentTarget.files.length > 0;
    this.setState({ newLogoPresent: logoPresent });
  };

  onLogoSave = (e) => {
    if (!this.state.newLogoPresent) {
      e.preventDefault();
    }

    NProgress.start();
  };

  render() {
    const timeZoneOptions = Object.values(this.props.timeZone).map((zone) => ({
      value: zone,
      label: zone,
    }));
    return (
      <div>
        {this.props.disabled && <NotifBanner style='warning'>You do not have permission to make changes to this team.</NotifBanner>}
        <dl {...this.props.disabled && { className: 'margin-top' }}>
          <dt>Details</dt>
          <dd>
            {this.renderDefaultStatus()}
            <label>
              <span>Team name</span>
              <input disabled={this.props.disabled} onChange={this.onTeamNameChanged} className='team-inputfield inline-block' type='text' defaultValue={this.state.team.name} />
              <a
                className={`small btn-primary${(this.shouldAllowTeamNameSave() ? '' : ' disabled')}`}
                onClick={this.onTeamNameSave}
              >
                Save
              </a>
            </label>

            <label>
              <span>Public URL</span>
              <input
                disabled={this.props.disabled}
                placeholder='Public URL'
                onChange={this.onSlugChanged}
                className='team-inputfield inline-block margin-bottom'
                type='text'
                defaultValue={this.state.newSlug}
              />
              <a
                className={`small btn-primary${(this.shouldAllowSlugSave() ? '' : ' disabled')}`}
                onClick={this.onSlugSave}
              >
                {this.textForSlugSaveButton()}
              </a>
              <CopyableLink linkURL={this.publicPortalURL()} />
            </label>

            <label>
              <span>Logo</span>
              {this.renderTeamLogo()}
              <form encType='multipart/form-data' method='post' action={`/teams/${this.state.team.friendly_id}`}>
                <input type='hidden' name='_method' value='patch' />
                <input disabled={this.props.disabled} type='file' name='team[logo]' onChange={this.onLogoChange} />
                <input
                  onClick={this.onLogoSave}
                  className={`btn-primary small${(this.state.newLogoPresent ? '' : ' disabled')}`}
                  type='submit'
                  value={this.state.team.logo.present ? 'Replace' : 'Add'}
                />
              </form>
            </label>

            <PublicPortalHeader
              team={this.props.team}
              disabled={this.props.disabled}
            />

            <label>
              <span>Time zone</span>
              <Select
                options={timeZoneOptions}
                disabled={this.props.disabled}
                value={timeZoneOptions.find(({ value }) => value === this.state.newTimeZone)}
                onChange={this.onTimeZoneChange}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    display: 'inline-block',
                    minWidth: '10rem',
                    zIndex: 500,
                  }),
                }}
              />

              <a
                className={`small btn-primary margin-left margin-top${(this.shouldAllowTimeZoneSave() ? '' : ' disabled')}`}
                onClick={this.onTimeZoneSave}
              >
                Save Time Zone
              </a>
            </label>

            <label>
              <span>Navbar Color</span>
              <ChromePicker
                disabled={this.props.disabled}
                color={this.state.newTeamColor || defaultTeamColor}
                disableAlpha
                onChangeComplete={this.onColorChange}
              />
              <a
                className={`small btn-primary margin-top${(this.shouldAllowColorSave() ? '' : ' disabled')}`}
                onClick={this.onColorSave}
              >
                Save Color
              </a>
            </label>

            <DomainManager
              teamId={this.state.team.friendly_id}
              disabled={this.props.disabled}
              domains={this.state.team.domains}
            />
          </dd>
        </dl>

        <dl className='margin-top-more'>
          <dt>
            Members
            <div className='helpertext'>Users can be added and removed from a team via <a href={`${this.props.team.friendly_id}/roles`}>Roles</a>. Team members can access all forms on a team.</div>
          </dt>
          <dd>
            <div className='teamsettings-permissions fullwidth'>
              <AttachedPeople
                people={this.props.team.members}
                onChange={this.updateMembers}
                attachableType='Team'
                attachableId={this.props.team.id}
                showEmail
                allowPseudo
                lockedUserIds={[this.props.currentUserId, this.props.team.creator_id]}
                allowToggle={false}
                disabled
                hideExternal
              />

            </div>
          </dd>
        </dl>

      </div>
    );
  }
}

TeamPage.propTypes = {
  team: PropTypes.object,
  currentUserId: PropTypes.number,
  disabled: PropTypes.bool,
  timeZone: PropTypes.object,
};

export default TeamPage;
