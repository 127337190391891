import React, { useState } from 'react';
import EditableInput from 'components/shared/editable-input';
import LoadingSpinner from 'components/shared/loading-spinner';

const UserProfile = (props) => {
  const [name, setName] = useState(props.user.name);
  const [username, setUsername] = useState(props.user.username);
  const [email, setEmail] = useState(props.user.email);

  function renderProfilePhoto() {
    return (
      <div className='margin-bottom-more'>
        <label htmlFor='user_avatar'>Avatar</label>
        <div>
          {renderImage()}
          <div>
            {props.user.has_avatar && removeAvatarButton()}
            <input onChange={(e) => { props.changeAvatar(e.currentTarget.files[0]); }} className='vmiddle' type='file' id='user_avatar' />
          </div>
        </div>
      </div>
    );
  }

  function renderImage() {
    if (props.imageLoading) {
      return (
        <LoadingSpinner className='margin-top margin-bottom' size='large' />
      );
    }

    const src = props.user.avatar_src;
    return (
      <img className='vmiddle margin-right margin-bottom' src={src} height='100px' />
    );
  }

  function removeAvatarButton() {
    return (
      <span>
        <button className='btn-link margin-right' onClick={() => { props.changeAvatar(''); }}>Remove Avatar</button>
      </span>
    );
  }

  function renderPersonalInfo() {
    return (
      <div>
        <EditableInput
          label='Name'
          onInputChanged={(e) => (setName(e.currentTarget.value))}
          onCancel={() => setName(props.user.name)}
          onSave={() => props.updateUser({ name })}
          id='user_name'
          value={name}
          key='user_name'
        />
        <EditableInput
          label='Username'
          onInputChanged={(e) => (setUsername(e.currentTarget.value))}
          onCancel={() => setUsername(props.user.username)}
          onSave={() => props.updateUser({ username })}
          id='user_username'
          value={username}
          key='user_username'
        />
        <EditableInput
          label='Email'
          onInputChanged={(e) => (setEmail(e.currentTarget.value))}
          onCancel={() => setEmail(props.user.email)}
          onSave={() => props.updateUser({ email })}
          id='user_email'
          value={email}
          key='user_email'
        />
      </div>
    );
  }

  return (
    <>
      <h1>Profile</h1>
      {renderProfilePhoto()}
      {renderPersonalInfo()}
    </>
  );
};

export default UserProfile;
