import React, { useEffect, useState, useContext } from 'react';
import {
  Elements, useStripe, useElements, PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PaymentStepActions from 'actions/payment-step-actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import Flash from 'components/shared/flash';
import InlineNotification from 'components/shared/inline-notification';
import PaymentIntentsApi from 'apis/payment-intents-api';
import Amount from './amount';
import AmountBreakdown from './amount-breakdown';

const StripeForm = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [isUpdating, setIsUpdating] = useState(props.updating);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [errored, setErrored] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    setIsUpdating(props.updating);
  }, [props.updating]);

  useEffect(() => {
    if (!paymentSuccessful) { return; }

    const interval = setInterval(pollForCharge, 2000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [paymentSuccessful]);

  function pollForCharge() {
    PaymentStepActions.pollForCharge(dispatch, props.paymentId, props.type);
  }

  const submitToStripe = async () => {
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      Flash.error(error.message);
      setIsProcessing(false);
    } else {
      setPaymentSuccessful(true);
    }
  };

  function handleConfirmFail() {
    setErrored(true);
    setIsProcessing(false);
  }

  function handleSubmit(event) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    PaymentIntentsApi.confirmPayment(props.paymentId, props.amount, props.paymentType)
      .done((res) => {
        if (res.confirmed) {
          submitToStripe();
        } else {
          handleConfirmFail();
        }
      });
  }

  function renderMessage() {
    if (isProcessing) {
      return (
        <div className='inline-block margin-top'>
          <LoadingSpinner className='vtop' />
          <span> Processing your payment</span>
        </div>
      );
    }
  }
  if (isUpdating) {
    return (
      <div className='align-center'>
        <LoadingSpinner size='large' />
        <div>Updating Form</div>
      </div>
    );
  }

  if (errored) {
    return (
      <InlineNotification className='margin-top margin-bottom'>
        This page is out of date, please refresh your browser
      </InlineNotification>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={props.handleChange} />
      {props.config.constituent_pays_fee
        && <AmountBreakdown amount={props.amount} fee={props.fee} /> }
      <div>
        {props.paymentType == 'us_bank_account' && <div>Bank payments may take 3-5 business days to process.</div>}
        {!isProcessing && (
          <button type='submit' className='btn-primary margin-top' disabled={isProcessing || !stripe || !elements || isUpdating} id='submit'>
            <span id='button-text'>
              Submit Payment
            </span>
          </button>
        ) }
      </div>
      {renderMessage()}
    </form>
  );
};

const StripeWrapper = (props) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [amount, setAmount] = useState(props.amount);
  const [editingAmount, setEditingAmount] = useState(!props.config.fixed);
  const [paymentType, setPaymentType] = useState('card');
  const [updating, setUpdating] = useState(false);

  const paymentId = props.type === 'PaymentRequirement' ? props.activeStep.id : props.activeStep.payment_id;

  useEffect(() => {
    setStripe(loadStripe(CityGrows.Server.stripeKey, { stripeAccount: `${CityGrows.Server.merchantAccountId}` }));
    if (!amount) { return; }

    getClientSecret(amount);
  }, []);

  useEffect(() => {
    if (props.onPaymentTypeChange !== undefined) {
      props.onPaymentTypeChange(paymentType);
    }
    if (!clientSecret) { return; }
    updatePaymentIntent(amount);
  }, [paymentType]);

  useEffect(() => {
    if (props.onPaymentAmountChange !== undefined) {
      props.onPaymentAmountChange(amount);
    }
  }, [amount]);

  function getClientSecret(amnt) {
    PaymentIntentsApi.create(paymentId, amnt, paymentType)
      .done((res) => {
        setClientSecret(res.cs);
      });
  }

  function updatePaymentIntent(amnt) {
    setUpdating(true);
    PaymentIntentsApi.update(paymentId, amnt, paymentType)
      .done(() => { setUpdating(false); });
  }

  function onAmountSave(amnt) {
    if (amnt < 1.00) {
      return Flash.error('Amount must be at least 1.00');
    }

    setAmount(Number(amnt));
    if (!clientSecret) {
      getClientSecret(amnt);
    } else {
      updatePaymentIntent(amnt);
    }

    setEditingAmount(false);
  }

  function handleChange(e) {
    setPaymentType(e.value.type);
  }

  function renderDisabledChargesWarning() {
    if (props.chargesEnabled || !CityGrows.Server.admin) { return; }

    return (
      <InlineNotification>
        <>
          <span>Payments disabled. Please add a payout account </span>
          <a href={`/teams/${props.teamFriendlyId}/merchant_accounts`}>here</a>
        </>
      </InlineNotification>
    );
  }

  function renderDisabledWarning() {
    return (
      <InlineNotification>
        <>
          <span>This process is not active. Payments disabled.</span>
        </>
      </InlineNotification>
    );
  }

  function renderStripeElement() {
    if (!amount || editingAmount) { return; }

    if (!clientSecret) {
      return (
        <div className='align-center'>
          <LoadingSpinner size='large' />
          <div>Loading Form</div>
        </div>
      );
    }

    return (
      <Elements stripe={stripe} options={{ clientSecret }}>
        <StripeForm
          {...props}
          amount={amount}
          paymentId={paymentId}
          handleChange={handleChange}
          updating={updating}
          paymentType={paymentType}
        />
      </Elements>
    );
  }

  return (
    <div>
      {renderDisabledChargesWarning()}
      {props.disabled && renderDisabledWarning()}
      <Amount
        amount={amount}
        config={props.config}
        disabled={!editingAmount || props.disabled}
        onAmountSave={onAmountSave}
        onEditClick={() => {
          setEditingAmount(true);
          setAmount(0.0);
        }}
      />
      {!props.disabled && renderStripeElement()}
    </div>
  );
};

export default StripeWrapper;
