import React from 'react';
import PropTypes from 'prop-types';
import { where } from 'underscore';
import Instructions from '../../shared/instructions';
import CollectInfoStep from './steps/collect-info-step';
import AssignResponsibilityStep from './steps/assign-responsibility-step';
import PaymentStep from './steps/payment-step';
import RequirementButtons from './requirements/requirement-buttons';
import RequireReviewStep from './steps/require-review-step';
import SupplementalRequirement from './requirements/supplemental-requirement';
import SurveyStep from './steps/survey-step';
import OutputsContainer from './outputs-container';

const CurrentStep = (props) => {
  const {
    step, initiatedFlow, activeStep, attemptedAdvance,
  } = props;
  function contentForStepType() {
    if (activeStep.type === 'SupplementalRequirementActiveStep') {
      return (
        <SupplementalRequirement
          requirement={step}
          collectedData={activeStep.collected_data}
          admin={props.admin}
          initiatedFlow={initiatedFlow}
          teamId={props.teamId}
        />
      );
    }

    switch (step.type) {
    case ('BasicStep'):
      if (!props.admin) {
        if (!step.external) {
          return (
            <div className='currentstep-stepsection'>
              <p>Submission is in progress. Nothing is needed from you at this time.</p>
            </div>
          );
        }
      }

      if (props.taskView) { return <></>}

      break;
    case ('CollectInfoStep'):
      return (
        <CollectInfoStep
          step={step}
          initiatedFlow={initiatedFlow}
          admin={props.admin}
          teamId={props.teamId}
          activeStep={activeStep}
          collectedData={activeStep.collected_data}
          attemptedAdvance={attemptedAdvance}
        />
      );

    case ('AssignResponsibilityStep'):
      return (
        <AssignResponsibilityStep
          step={step}
          activeStep={activeStep}
          currentUserId={props.currentUserId}
          initiatedFlow={initiatedFlow}
          admin={props.admin}
        />
      );

    case ('RequireReviewStep'):
      return (
        <RequireReviewStep
          activeStep={activeStep}
          currentUserId={props.currentUserId}
          admin={props.admin}
          initiatedFlow={initiatedFlow}
          discussions={props.discussions}
          stepId={step.id}
          onOpenDiscussionClick={props.onOpenDiscussionClick}
        />
      );

    case ('PaymentStep'):
      return (
        <PaymentStep
          activeStep={activeStep}
          supplemental={false}
          step={step}
          admin={props.admin}
          teamFriendlyId={props.teamFriendlyId}
          authorities={props.authorities}
          active={initiatedFlow.is_active}
        />
      );

    case ('SurveyStep'):
      return (
        <SurveyStep
          activeStep={activeStep}
          step={step}
          admin={props.admin}
          active={initiatedFlow.is_active}
          taskView={props.taskView}
        />
      );

    default:
      throw `Unrecognized step type ${step.type}`;
    }
  }

  function renderRequirementButtons() {
    if (step.generic_type === 'Step') { return; }

    return (
      <RequirementButtons
        requirementId={step.id}
        type={step.type}
        requirementCompleted={step.completed}
        requirementSent={step.sent}
        requirementCanceled={step.canceled}
      />
    );
  }

  function renderRequirementDescription() {
    if (step.generic_type === 'Step' || !step.description) { return; }

    return (
      <div>{step.description}</div>
    );
  }

  function renderOutputsIssuedOnStep() {
    const outputs = where(initiatedFlow.issued_outputs, { issued_on_step_identity: step.identity });

    if (outputs.length === 0) { return; }

    return (
      <div className='currentstep-stepsection cf'>
        <OutputsContainer outputs={outputs} />
      </div>
    );
  }

  if (props.taskView) {
    return contentForStepType()
  }

  return (
    <div className='currentstep'>
      <div className='flowcontent-main'>
        <div className='currentstep-stepsection cf'>
          {renderRequirementButtons()}
          <div className='currentstep-title'>{step.name_or_default}</div>
          {renderRequirementDescription()}
          <Instructions admin={props.admin} initiatedFlow={initiatedFlow} step={step} />
        </div>

        {renderOutputsIssuedOnStep()}

        {contentForStepType()}
      </div>
    </div>
  );
};

CurrentStep.propTypes = {
  admin: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  activeStep: PropTypes.object.isRequired,
  onOpenDiscussionClick: PropTypes.func.isRequired,
  initiatedFlow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_active: PropTypes.bool.isRequired,
  }).isRequired,
  step: PropTypes.shape({
    external: PropTypes.bool.isRequired,
    sent: PropTypes.bool,
    instructions: PropTypes.text,
    generic_type: PropTypes.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    description: PropTypes.string,
    name_or_default: PropTypes.string.isRequired,
  }).isRequired,

};

export default CurrentStep;
