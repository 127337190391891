import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import Actions from 'actions/survey-submission-actions';
import TimeAgo from 'react-timeago';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import { confirm } from 'modules/alert-confirm';
import XClose from 'components/shared/x-close';

const SurveySubmissionLine = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  function onSubmissionClick(e) {
    e.preventDefault()
    props.history.push(`/survey-response/${props.submission.id}`);
  }

  function onDeleteClick(e) {
    e.stopPropagation();
    e.preventDefault();
    confirm('Delete this submission?', 'Are you sure you want to delete this submission?', () => {
      Actions.destroy(props.submission.id)
        .done(() => {
          Actions.dispatchSubmissionDestroyed(dispatch, props.submission.id);
        });
    });
  }

  function renderEmail() {
    if (props.submission.email) {
      return (
        <span className='margin-left'>
          {props.submission.email}
        </span>
      );
    }
  }

  return (
    <li className='clickable' onClick={onSubmissionClick} >
      <a href={`/initiated_flows/manager/${props.initiatedFlowId}#/survey-response/${props.submission.id}`} onClick={onSubmissionClick} >
        <strong>#{props.submission.scoped_id}</strong>
        {renderEmail()}
        <XClose onClick={onDeleteClick} label='Delete submission' buttonClass='float-right' />
        <TimeAgo
          date={props.submission.created_at}
          className='float-right color-text-medium margin-right'
        />
      </a>
    </li>
  );
};

SurveySubmissionLine.propTypes = {
  submission: PropTypes.object.isRequired,
};

export default withRouter(SurveySubmissionLine)
