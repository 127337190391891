import PropTypes from 'prop-types';
import React, { Component } from 'react';
import UserDisc from 'components/shared/user-disc';
import RoleDisc from 'components/shared/role-disc';
import XClose from 'components/shared/x-close';

class AttachedPerson extends Component {
  render() {
    return (
      <li className={this.props.disabled ? 'disabled' : ''}>
        { this.props.person.type === 'User' ? (
          <UserDisc
            user={this.props.person.user}
            options={{
              showName: true,
              showEmail: this.props.showEmail,
            }}
          />
        ) : (
          <RoleDisc
            role={this.props.person}
          />
        )}
        <XClose
          onClick={this.props.disabled ? null : () => this.props.onDeleteClick(this.props.person)}
          id={`delete-${this.props.person.id}`}
          label='Remove person'
        />
      </li>
    );
  }
}

AttachedPerson.propTypes = {
  showEmail: PropTypes.bool,
  disabled: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,

  person: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    pseudo: PropTypes.bool,
  }).isRequired,
};

AttachedPerson.defaultProps = {
  showEmail: false,
};

export default AttachedPerson;
